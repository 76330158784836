*{
  margin: 0;
  padding: 0;
  box-sizing: 0;

}

a{
  color: #555;
}

nav{
  z-index:1;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #F0F0F0;
  box-shadow: 2px 7px 10px rgba(35, 35, 35, 0.20);

}

header{
  height: 100%;
  width: 100%;
  margin-top: 90px;
}


html,
body  {


  color: #fff;
  font-family: 'Lato', Arial, sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  background-color: #F0F0F0;


}

.body-img{
  background-image: url(img/background-greet.svg);
  background-image: url(img/background-greet.svg);
  background-size: cover;
  background-position: center;
}

.background-img{
  background-image: -webkit-linear-gradient(rgba(35, 35, 35, 0.60), rgba(35, 35, 35, 0.60)), url(img/line.jpg);
  background-image: linear-gradient(rgba(35, 35, 35, 0.60), rgba(35, 35, 35, 0.60)), url(img/line.jpg);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin: 50px 50px 80px 50px;
}

.background-img-aboutus{
  background-image: -webkit-linear-gradient(rgba(35, 35, 35, 0.90), rgba(35, 35, 35, 0.90)), url(img/advice.jpg);
  background-image: linear-gradient(rgba(35, 35, 35, 0.90), rgba(35, 35, 35, 0.90)), url(img/advice.jpg);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin: 50px 50px 80px 50px;
}

.vr-man{
  background-image: -webkit-linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(img/vr.jpg);
  background-image: linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(img/vr.jpg);
  background-size: cover;
  background-position: 10px 70px;
  height: 300px;
  background-attachment:fixed;
}

.drone{
  background-image: -webkit-linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(img/drone.jpg);
  background-image: linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(img/drone.jpg);
  background-size: cover;
  background-position: 5px 0px;
  height: 340px;
  background-attachment:fixed;
}

h1,
h2{
  font-weight: 300;
  text-transform: uppercase;
}

h1{
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 240%;
  word-spacing: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;

}

h2{
  font-size: 180%;
  word-spacing: 2px;
  text-align: center;
  margin: -30px 0 10px 0;
  letter-spacing: 1px;
  color: #555;
}
h3 {
    font-size: 110%;
    margin-bottom: 15px;
}

h2:after {
    display: block;
    height: 1px;
    background-color: #bf0f0f;
    content: " ";
    width: 200px;
    margin: 0 auto;
    margin-top: 3px;
}

.long-copy {
    text-align: center;
    width: 90%;
    margin-left: 5%;
    color: #555;
}

.row {
    max-width: 1140px;
    margin: 0 auto;

}


/* Service box*/
.box{
  margin: 20px 60px 0 0;
  font-size: 17px;
}
.box-2{
  margin: 20px 60px 0 0;
}

/* methodology box*/
.box-3{

  left: 50px;
  margin: 40px 60px 0 0;
  font-size: 20px;
}



/*
Service lines
.vertical-lines{
  width:1px;
  height: 400px;
  background: grey;
  position: relative;
  left: 700px;
  top: 300px;
}
*/

/* ABOUT US SECTION */
.text-box-aboutus{
  position: absolute;
  width: 160px;
  margin: 35% 13%;
  font-size: 32px;
  border-bottom: 2px solid #9E2323;

}

.circle-aboutus{
  border: 2px solid #9E2323;
  height: 200px;
  width: 200px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  margin: 145px 125px;
}




/* Main navi */
.main-nav {
    list-style: none;
    margin-top: 15px;


}

.main-nav li {
    display: inline-block;
    margin-left: 150px;
    margin-bottom: 10px;
}

.main-nav li a:link,
.main-nav li a:visited {
    padding: 8px 0;
    color: #555;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 80%;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 0.2s;
    transition: border-bottom 0.2s;
    font-family: 'Lato', Arial, sans-serif;
    font-weight: 30px;
}

.main-nav li a:hover,
.main-nav li a:active {
    border-bottom: 2px solid #e67e22;
}


/*----- */

.social-media li{
  list-style: none;
  margin-top: 30px;
  display: inline-block;
  margin-left: 90px;
}

/* section */

section {
    padding: 100px 0;
}
.section-vr-img{
    padding: 10px 0 10px 0;
}
.section-drone-img{
  padding: 0px 0 0px 0;
  margin-bottom: 0px;
}

.section-aboutus{
  height: 590px;
  padding: 0 0 0 0;
  background-image: url(img/aboutus-background.svg);
  background-image: url(img/aboutus-background.svg);
  background-size: cover;
  background-position: center;

}

.section-services{
  margin-bottom: 10px;
  color: #555;
  background-image: url(img/services-background.svg);
  background-image: url(img/services-background.svg);
  background-size: cover;
  background-position: center;

}

.section-methodology{
  margin-bottom: 10px;
  color: #555;
  background-image: url(img/methodology-background.svg);
  background-image: url(img/methodology-background.svg);
  background-size: cover;
  background-position: center;
}

.section-contact{
  padding: 0 0 0 0;
  margin-bottom: 20px;

}




/*----- */

/* contact  */
.contact{
  height: 300px;
  width: 500px;
  background-color: rgba(149, 40, 40);
}

.hiring{
  margin-left: 2px;
  height: 300px;
  width: 64%;
  background-color: rgba(139, 54, 66);
}

.contact-text{
    width: 200px;
    border-bottom: 1px solid #fff;
    margin-left: 150px;
    margin-top: 100px;
    font-size: 32px;
    text-align: center;

}

.hiring-text{
  margin: 50px 100px;
  font-size: 22px;
}

.black-section{
  margin-top: -13px;
  margin-left: 0;
  background-color: black;
  height: 100px;
  width: 100%;
  text-align: center;
}

.black-section-text{
  margin-top: 40px;
}

/* methodology icons */

.methodology-icons-network,
.methodology-icons-computer,
.methodology-icons-pen{
  margin: 10px 95px;
}
/* button */
.btn:link,
.btn:visited,
input[type=submit] {
  position: absolute;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    margin: 20% 41%;
  }


.btn-full:link,
.btn-full:visited,
input[type=submit] {
    background-color: #9E2323;
    border: 1px solid #9E2323;
    color: #fff;
    margin-right: 15px;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #bf0f0f;
}

.btn-full:hover,
.btn-full:active {
    border: 1px solid #9E2323;
}

/*----- */

/* text box */
.text-box{
  position: absolute;
  width: 32%;
  margin: 10% 45%;
  transform: translate(-50%, -50%);
  border-top: 2px solid #d2d7da;
  border-bottom: 2px solid #d2d7da;
  text-indent: 15px;
  text-align: center;
}

.text-box-2{
  position: absolute;
  text-align: center;
  font-size: 16px;
  width: 50%;
  margin: 16% 47%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-box-3{
  color: #555;
  position: absolute;
  text-align: center;
  font-size: 18px;
  top: 80%;
  width: auto;

}

/*----- */
