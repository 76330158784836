

@media only screen and (max-width: 1400px) {
  .main-nav li {  display: inline-block; margin-left: 120px;}
  .text-box { padding: 0 4%; top:14%; text-align: center;}
  .text-box-2 { top: 20%;}
  .btn{ top: 25%; }



  .box{margin: 20px 30px 0 0; font-size: 15px;}

  .box-3{margin: 40px 40px 0 0; }



  .circle-aboutus{
    border: 0px solid #9E2323;
  }
  .text-box-aboutus{border-bottom: none;  width: 200px;}
  .text-aboutus{font-size: 18px;}
  .contact{width: 100%;}
  .hiring{ height: 300px; width: 100%;}
  .hiring-text{ font-size: 21px;}

  .long-copy {
      width: 80%;
      margin-left: 10%;
  }

    .row { padding: 0 2%; }
}

/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {
  .main-nav li {  display: inline-block; margin-left: 120px;}
  .text-box { padding: 0 4%; top:13%; text-align: center;}
  .text-box-2 { top: 19%;}
  .btn{ top: 22%; left: 2%;}

  .vr-man{background-position: 20px -150px; height: 300px;}

  .box{margin: 20px 30px 0 0;
  font-size: 15px;}

  .box-3{margin: 40px 40px 0 0; }

  .drone{background-position: 0px -200px; height: 250px;}

  .circle-aboutus{
    border: 0px solid #9E2323;
  }
  .text-box-aboutus{border-bottom: none;  width: 200px;}
  .text-aboutus{font-size: 18px;}
  .contact{width: 100%;}
  .hiring{ height: 300px; width: 100%;}
  .hiring-text{ font-size: 21px;}

  .long-copy {
      width: 80%;
      margin-left: 10%;
  }

    .row { padding: 0 2%; }
}


/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {

    body { font-size: 18px; }
    section { padding: 60px 0; }

    .main-nav li {  display: inline-block; margin-left: 80px;}
    .text-box { padding: 0 4%; top: 18%; text-align: center;}
    .text-box-2 { top: 32%;}
    .btn{ margin: -10% -5%; top: 37%; left: 2%;}

    .vr-man{background-position: 20px 0; height: 200px;}

    .box{margin: 20px 30px 0 0;
    font-size: 15px;}

    .box-3{margin: 40px 40px 0 0; }

    .drone{background-position: -100px 40px; height: 200px;}

    .circle-aboutus{
      border: 0px solid #9E2323;
    }
    .text-box-aboutus{ top: 10%; border-bottom: none; left: -25%;}
    .text-aboutus{font-size: 16px;}
    .contact{width: 100%;}
    .hiring{ height: 300px; width: 100%;}
    .hiring-text{ font-size: 21px;}

    .long-copy {
        width: 80%;
        margin-left: 10%;
    }

    .steps-box { margin-top: 10px; }
    .steps-box:last-child { margin-top: 10px; }
    .works-steps { margin-bottom: 40px; }
    .works-step:last-of-type { margin-bottom: 60px; }

    .app-screen { width: 50%; }

    .icon-small {
        width: 17px;
        margin-right: 5px;
    }

    .city-feature { font-size: 90%; }

    .plan-box {
        width: 100%;
        margin-left: 0%;
    }

    .plan-price { font-size: 250%; }
    .contact-form { width: 80%; }
}





/* Small phones to small tablets: from 481px to 767px */ /* done */
@media only screen and (max-width: 767px) {
    body { font-size: 16px; }


    .row,
    .text-box { padding: 0 4%; top: 20%;}
    .text-box-2 { top: 35%;}

    .text-box-aboutus{ top: auto; border-bottom: none; color: #555; margin: -70% -34%;}
    .text-aboutus{color: #555; font-size: 18px;}
    .background-img-aboutus{height: 0; }

    .btn{ top:50%;left: 1%;}
    .col {
        width: 100%;
        margin: 0 0 4% 0;
    }
    .circle-aboutus{
      border: 0px solid #9E2323;
    }
    .social-media{ margin-left: -60px; display: block; }
    .contact-text{margin-left:90px;}
    .hiring{ height: 300px; width: 100%;}
    .hiring-text{margin: 60px 100px; font-size: 16px;}

    .vr-man{background-position: -250px -0px; height: 200px;}
    .drone{background-position: -250px -0px; height: 200px;}

    .main-nav li {  display: inline-block; margin-left: 50px;}

    h1 { font-size: 180%; }
    h2 { font-size: 150%; }

    .long-copy {
        width: 100%;
        margin-left: 0%;
    }

    .app-screen { width: 40%; }
    .steps-box:first-child { text-align: center; }

    .works-step div {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        padding: 4px;
        font-size: 120%;
    }

    .works-step { margin-bottom: 20px; }
    .works-step:last-of-type { margin-bottom: 20px; }

}



/* Small phones: from 0 to 480px */ /* done */
@media only screen and (max-width: 480px) {

    .text-box{ margin: 10% 37%; }
    .text-box-2 { margin: 27% 37%; }
    .vr-man{background-position: -300px 70px;}
    .drone{background-position: -290px 0px;}

    .btn{top: 55%; left: -8%;}

    .hiring-text{margin: 50px 90px;}
    .social-media{ margin-left: -80px; display: block; }
    .text-box-aboutus{ margin: -70% -34%; border-bottom: none; color: #555;}
}

@media only screen and (max-width: 350px) {

    .text-box{ margin: 15% 37%; }
    .text-box-2 { margin: 45% 37%; }
    .vr-man{background-position: -300px 70px;}
    .drone{background-position: -290px 0px; }

    .btn{top: 70%; left: -8%;}

    .hiring-text{margin: 50px 90px;}
    .social-media{ margin-left: -100px;  }
    .text-box-aboutus{ margin: -70% -34%; border-bottom: none; color: #555;}
}
