
/*  SECTIONS  ============================================================================= */

.section {
	clear: both;
	padding: 0px;
	margin: 0px;
}

/*  GROUPING  ============================================================================= */

.row {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

.row:before,
.row:after {
    content:"";
    display:table;
}
.row:after {
    clear:both;
}

/*  GRID COLUMN SETUP   ==================================================================== */

.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;

}

.col:first-child { margin-left: 0; } /* all browsers except IE6 and lower */


/*  REMOVE MARGINS AS ALL GO FULL WIDTH AT 480 PIXELS */

@media only screen and (max-width: 480px) {
	.col {
		/*margin: 1% 0 1% 0%;*/
        margin: 0;
	}
}


/*  GRID OF TWO   ============================================================================= */


.span-2-of-2 {
	width: 100%;
}

.span-1-of-2 {
	width: 49.2%;
}

/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-2-of-2 {
		width: 100%;
	}
	.span-1-of-2 {
		width: 100%;
	}
}


/*  GRID OF THREE   ============================================================================= */


.span-3-of-3 {
	width: 100%;
}

.span-2-of-3 {
	width: 66.13%;
}

.span-1-of-3 {
	width: 32.26%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-3-of-3 {
		width: 100%;
	}
	.span-2-of-3 {
		width: 100%;
	}
	.span-1-of-3 {
		width: 100%;
	}
}

/*  GRID OF FOUR   ============================================================================= */


.span-4-of-4 {
	width: 100%;
}

.span-3-of-4 {
	width: 74.6%;
}

.span-2-of-4 {
	width: 49.2%;
}

.span-1-of-4 {
	width: 23.8%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-4-of-4 {
		width: 100%;
	}
	.span-3-of-4 {
		width: 100%;
	}
	.span-2-of-4 {
		width: 100%;
	}
	.span-1-of-4 {
		width: 100%;
	}
}


/*  GRID OF FIVE   ============================================================================= */


.span-5-of-5 {
	width: 100%;
}

.span-4-of-5 {
  	width: 79.68%;
}

.span-3-of-5 {
  	width: 59.36%;
}

.span-2-of-5 {
  	width: 39.04%;
}

.span-1-of-5 {
  	width: 18.72%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-5-of-5 {
		width: 100%;
	}
	.span-4-of-5 {
		width: 100%;
	}
	.span-3-of-5 {
		width: 100%;
	}
	.span-2-of-5 {
		width: 100%;
	}
	.span-1-of-5 {
		width: 100%;
	}
}


/*  GRID OF SIX   ============================================================================= */


.span-6-of-6 {
	width: 100%;
}

.span-5-of-6 {
  	width: 83.06%;
}

.span-4-of-6 {
  	width: 66.13%;
}

.span-3-of-6 {
  	width: 49.2%;
}

.span-2-of-6 {
  	width: 32.26%;
}

.span-1-of-6 {
  	width: 15.33%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-6-of-6 {
		width: 100%;
	}
	.span-5-of-6 {
		width: 100%;
	}
	.span-4-of-6 {
		width: 100%;
	}
	.span-3-of-6 {
		width: 100%;
	}
	.span-2-of-6 {
		width: 100%;
	}
	.span-1-of-6 {
		width: 100%;
	}
}



/*  GRID OF SEVEN   ============================================================================= */


.span-7-of-7 {
	width: 100%;
}

.span-6-of-7 {
	width: 85.48%;
}

.span-5-of-7 {
  	width: 70.97%;
}

.span-4-of-7 {
  	width: 56.45%;
}

.span-3-of-7 {
  	width: 41.94%;
}

.span-2-of-7 {
  	width: 27.42%;
}

.span-1-of-7 {
  	width: 12.91%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-7-of-7 {
		width: 100%;
	}
	.span-6-of-7 {
		width: 100%;
	}
	.span-5-of-7 {
		width: 100%;
	}
	.span-4-of-7 {
		width: 100%;
	}
	.span-3-of-7 {
		width: 100%;
	}
	.span-2-of-7 {
		width: 100%;
	}
	.span-1-of-7 {
		width: 100%;
	}
}


/*  GRID OF EIGHT   ============================================================================= */


.span-8-of-8 {
	width: 100%;
}

.span-7-of-8 {
	width: 87.3%;
}

.span-6-of-8 {
	width: 74.6%;
}

.span-5-of-8 {
	width: 61.9%;
}

.span-4-of-8 {
	width: 49.2%;
}

.span-3-of-8 {
	width: 36.5%;
}

.span-2-of-8 {
	width: 23.8%;
}

.span-1-of-8 {
	width: 11.1%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-8-of-8 {
		width: 100%;
	}
	.span-7-of-8 {
		width: 100%;
	}
	.span-6-of-8 {
		width: 100%;
	}
	.span-5-of-8 {
		width: 100%;
	}
	.span-4-of-8 {
		width: 100%;
	}
	.span-3-of-8 {
		width: 100%;
	}
	.span-2-of-8 {
		width: 100%;
	}
	.span-1-of-8 {
		width: 100%;
	}
}


/*  GRID OF NINE   ============================================================================= */


.span-9-of-9 {
	width: 100%;
}

.span-8-of-9 {
	width: 88.71%;
}

.span-7-of-9 {
	width: 77.42%;
}

.span-6-of-9 {
	width: 66.13%;
}

.span-5-of-9 {
	width: 54.84%;
}

.span-4-of-9 {
	width: 43.55%;
}

.span-3-of-9 {
	width: 32.26%;
}

.span-2-of-9 {
	width: 20.97%;
}

.span-1-of-9 {
	width: 9.68%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-9-of-9 {
		width: 100%;
	}
	.span-8-of-9 {
		width: 100%;
	}
	.span-7-of-9 {
		width: 100%;
	}
	.span-6-of-9 {
		width: 100%;
	}
	.span-5-of-9 {
		width: 100%;
	}
	.span-4-of-9 {
		width: 100%;
	}
	.span-3-of-9 {
		width: 100%;
	}
	.span-2-of-9 {
		width: 100%;
	}
	.span-1-of-9 {
		width: 100%;
	}
}


/*  GRID OF TEN   ============================================================================= */


.span-10-of-10 {
	width: 100%;
}

.span-9-of-10 {
	width: 89.84%;
}

.span-8-of-10 {
	width: 79.68%;
}

.span-7-of-10 {
	width: 69.52%;
}

.span-6-of-10 {
	width: 59.36%;
}

.span-5-of-10 {
	width: 49.2%;
}

.span-4-of-10 {
	width: 39.04%;
}

.span-3-of-10 {
	width: 28.88%;
}

.span-2-of-10 {
	width: 18.72%;
}

.span-1-of-10 {
	width: 8.56%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-10-of-10 {
		width: 100%;
	}
	.span-9-of-10 {
		width: 100%;
	}
	.span-8-of-10 {
		width: 100%;
	}
	.span-7-of-10 {
		width: 100%;
	}
	.span-6-of-10 {
		width: 100%;
	}
	.span-5-of-10 {
		width: 100%;
	}
	.span-4-of-10 {
		width: 100%;
	}
	.span-3-of-10 {
		width: 100%;
	}
	.span-2-of-10 {
		width: 100%;
	}
	.span-1-of-10 {
		width: 100%;
	}
}


/*  GRID OF ELEVEN   ============================================================================= */

.span-11-of-11 {
	width: 100%;
}

.span-10-of-11 {
	width: 90.76%;
}

.span-9-of-11 {
	width: 81.52%;
}

.span-8-of-11 {
	width: 72.29%;
}

.span-7-of-11 {
	width: 63.05%;
}

.span-6-of-11 {
	width: 53.81%;
}

.span-5-of-11 {
	width: 44.58%;
}

.span-4-of-11 {
	width: 35.34%;
}

.span-3-of-11 {
	width: 26.1%;
}

.span-2-of-11 {
	width: 16.87%;
}

.span-1-of-11 {
	width: 7.63%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-11-of-11 {
		width: 100%;
	}
	.span-10-of-11 {
		width: 100%;
	}
	.span-9-of-11 {
		width: 100%;
	}
	.span-8-of-11 {
		width: 100%;
	}
	.span-7-of-11 {
		width: 100%;
	}
	.span-6-of-11 {
		width: 100%;
	}
	.span-5-of-11 {
		width: 100%;
	}
	.span-4-of-11 {
		width: 100%;
	}
	.span-3-of-11 {
		width: 100%;
	}
	.span-2-of-11 {
		width: 100%;
	}
	.span-1-of-11 {
		width: 100%;
	}
}


/*  GRID OF TWELVE   ============================================================================= */

.span-12-of-12 {
	width: 100%;
}

.span-11-of-12 {
	width: 91.53%;
}

.span-10-of-12 {
	width: 83.06%;
}

.span-9-of-12 {
	width: 74.6%;
}

.span-8-of-12 {
	width: 66.13%;
}

.span-7-of-12 {
	width: 57.66%;
}

.span-6-of-12 {
	width: 49.2%;
}

.span-5-of-12 {
	width: 40.73%;
}

.span-4-of-12 {
	width: 32.26%;
}

.span-3-of-12 {
	width: 23.8%;
}

.span-2-of-12 {
	width: 15.33%;
}

.span-1-of-12 {
	width: 6.86%;
}


/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

@media only screen and (max-width: 480px) {
	.span-12-of-12 {
		width: 100%;
	}
	.span-11-of-12 {
		width: 100%;
	}
	.span-10-of-12 {
		width: 100%;
	}
	.span-9-of-12 {
		width: 100%;
	}
	.span-8-of-12 {
		width: 100%;
	}
	.span-7-of-12 {
		width: 100%;
	}
	.span-6-of-12 {
		width: 100%;
	}
	.span-5-of-12 {
		width: 100%;
	}
	.span-4-of-12 {
		width: 100%;
	}
	.span-3-of-12 {
		width: 100%;
	}
	.span-2-of-12 {
		width: 100%;
	}
	.span-1-of-12 {
		width: 100%;
	}
}

*{
  margin: 0;
  padding: 0;
  box-sizing: 0;

}

a{
  color: #555;
}

nav{
  z-index:1;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #F0F0F0;
  box-shadow: 2px 7px 10px rgba(35, 35, 35, 0.20);

}

header{
  height: 100%;
  width: 100%;
  margin-top: 90px;
}


html,
body  {


  color: #fff;
  font-family: 'Lato', Arial, sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  background-color: #F0F0F0;


}

.body-img{
  background-image: url(/static/media/background-greet.37b7cf08.svg);
  background-image: url(/static/media/background-greet.37b7cf08.svg);
  background-size: cover;
  background-position: center;
}

.background-img{
  background-image: linear-gradient(rgba(35, 35, 35, 0.60), rgba(35, 35, 35, 0.60)), url(/static/media/line.cbc6e4af.jpg);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin: 50px 50px 80px 50px;
}

.background-img-aboutus{
  background-image: linear-gradient(rgba(35, 35, 35, 0.90), rgba(35, 35, 35, 0.90)), url(/static/media/advice.01304d27.jpg);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin: 50px 50px 80px 50px;
}

.vr-man{
  background-image: linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(/static/media/vr.66841303.jpg);
  background-size: cover;
  background-position: 10px 70px;
  height: 300px;
  background-attachment:fixed;
}

.drone{
  background-image: linear-gradient(rgba(73, 73, 73, 0.10), rgba(73, 73, 73, 0.10)), url(/static/media/drone.0cfb3ce9.jpg);
  background-size: cover;
  background-position: 5px 0px;
  height: 340px;
  background-attachment:fixed;
}

h1,
h2{
  font-weight: 300;
  text-transform: uppercase;
}

h1{
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 240%;
  word-spacing: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;

}

h2{
  font-size: 180%;
  word-spacing: 2px;
  text-align: center;
  margin: -30px 0 10px 0;
  letter-spacing: 1px;
  color: #555;
}
h3 {
    font-size: 110%;
    margin-bottom: 15px;
}

h2:after {
    display: block;
    height: 1px;
    background-color: #bf0f0f;
    content: " ";
    width: 200px;
    margin: 0 auto;
    margin-top: 3px;
}

.long-copy {
    text-align: center;
    width: 90%;
    margin-left: 5%;
    color: #555;
}

.row {
    max-width: 1140px;
    margin: 0 auto;

}


/* Service box*/
.box{
  margin: 20px 60px 0 0;
  font-size: 17px;
}
.box-2{
  margin: 20px 60px 0 0;
}

/* methodology box*/
.box-3{

  left: 50px;
  margin: 40px 60px 0 0;
  font-size: 20px;
}



/*
Service lines
.vertical-lines{
  width:1px;
  height: 400px;
  background: grey;
  position: relative;
  left: 700px;
  top: 300px;
}
*/

/* ABOUT US SECTION */
.text-box-aboutus{
  position: absolute;
  width: 160px;
  margin: 35% 13%;
  font-size: 32px;
  border-bottom: 2px solid #9E2323;

}

.circle-aboutus{
  border: 2px solid #9E2323;
  height: 200px;
  width: 200px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  margin: 145px 125px;
}




/* Main navi */
.main-nav {
    list-style: none;
    margin-top: 15px;


}

.main-nav li {
    display: inline-block;
    margin-left: 150px;
    margin-bottom: 10px;
}

.main-nav li a:link,
.main-nav li a:visited {
    padding: 8px 0;
    color: #555;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 80%;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.2s;
    font-family: 'Lato', Arial, sans-serif;
    font-weight: 30px;
}

.main-nav li a:hover,
.main-nav li a:active {
    border-bottom: 2px solid #e67e22;
}


/*----- */

.social-media li{
  list-style: none;
  margin-top: 30px;
  display: inline-block;
  margin-left: 90px;
}

/* section */

section {
    padding: 100px 0;
}
.section-vr-img{
    padding: 10px 0 10px 0;
}
.section-drone-img{
  padding: 0px 0 0px 0;
  margin-bottom: 0px;
}

.section-aboutus{
  height: 590px;
  padding: 0 0 0 0;
  background-image: url(/static/media/aboutus-background.0736ab1d.svg);
  background-image: url(/static/media/aboutus-background.0736ab1d.svg);
  background-size: cover;
  background-position: center;

}

.section-services{
  margin-bottom: 10px;
  color: #555;
  background-image: url(/static/media/services-background.8990105d.svg);
  background-image: url(/static/media/services-background.8990105d.svg);
  background-size: cover;
  background-position: center;

}

.section-methodology{
  margin-bottom: 10px;
  color: #555;
  background-image: url(/static/media/methodology-background.30a6f55a.svg);
  background-image: url(/static/media/methodology-background.30a6f55a.svg);
  background-size: cover;
  background-position: center;
}

.section-contact{
  padding: 0 0 0 0;
  margin-bottom: 20px;

}




/*----- */

/* contact  */
.contact{
  height: 300px;
  width: 500px;
  background-color: rgba(149, 40, 40);
}

.hiring{
  margin-left: 2px;
  height: 300px;
  width: 64%;
  background-color: rgba(139, 54, 66);
}

.contact-text{
    width: 200px;
    border-bottom: 1px solid #fff;
    margin-left: 150px;
    margin-top: 100px;
    font-size: 32px;
    text-align: center;

}

.hiring-text{
  margin: 50px 100px;
  font-size: 22px;
}

.black-section{
  margin-top: -13px;
  margin-left: 0;
  background-color: black;
  height: 100px;
  width: 100%;
  text-align: center;
}

.black-section-text{
  margin-top: 40px;
}

/* methodology icons */

.methodology-icons-network,
.methodology-icons-computer,
.methodology-icons-pen{
  margin: 10px 95px;
}
/* button */
.btn:link,
.btn:visited,
input[type=submit] {
  position: absolute;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    margin: 20% 41%;
  }


.btn-full:link,
.btn-full:visited,
input[type=submit] {
    background-color: #9E2323;
    border: 1px solid #9E2323;
    color: #fff;
    margin-right: 15px;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #bf0f0f;
}

.btn-full:hover,
.btn-full:active {
    border: 1px solid #9E2323;
}

/*----- */

/* text box */
.text-box{
  position: absolute;
  width: 32%;
  margin: 10% 45%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-top: 2px solid #d2d7da;
  border-bottom: 2px solid #d2d7da;
  text-indent: 15px;
  text-align: center;
}

.text-box-2{
  position: absolute;
  text-align: center;
  font-size: 16px;
  width: 50%;
  margin: 16% 47%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-box-3{
  color: #555;
  position: absolute;
  text-align: center;
  font-size: 18px;
  top: 80%;
  width: auto;

}

/*----- */



@media only screen and (max-width: 1400px) {
  .main-nav li {  display: inline-block; margin-left: 120px;}
  .text-box { padding: 0 4%; top:14%; text-align: center;}
  .text-box-2 { top: 20%;}
  .btn{ top: 25%; }



  .box{margin: 20px 30px 0 0; font-size: 15px;}

  .box-3{margin: 40px 40px 0 0; }



  .circle-aboutus{
    border: 0px solid #9E2323;
  }
  .text-box-aboutus{border-bottom: none;  width: 200px;}
  .text-aboutus{font-size: 18px;}
  .contact{width: 100%;}
  .hiring{ height: 300px; width: 100%;}
  .hiring-text{ font-size: 21px;}

  .long-copy {
      width: 80%;
      margin-left: 10%;
  }

    .row { padding: 0 2%; }
}

/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {
  .main-nav li {  display: inline-block; margin-left: 120px;}
  .text-box { padding: 0 4%; top:13%; text-align: center;}
  .text-box-2 { top: 19%;}
  .btn{ top: 22%; left: 2%;}

  .vr-man{background-position: 20px -150px; height: 300px;}

  .box{margin: 20px 30px 0 0;
  font-size: 15px;}

  .box-3{margin: 40px 40px 0 0; }

  .drone{background-position: 0px -200px; height: 250px;}

  .circle-aboutus{
    border: 0px solid #9E2323;
  }
  .text-box-aboutus{border-bottom: none;  width: 200px;}
  .text-aboutus{font-size: 18px;}
  .contact{width: 100%;}
  .hiring{ height: 300px; width: 100%;}
  .hiring-text{ font-size: 21px;}

  .long-copy {
      width: 80%;
      margin-left: 10%;
  }

    .row { padding: 0 2%; }
}


/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {

    body { font-size: 18px; }
    section { padding: 60px 0; }

    .main-nav li {  display: inline-block; margin-left: 80px;}
    .text-box { padding: 0 4%; top: 18%; text-align: center;}
    .text-box-2 { top: 32%;}
    .btn{ margin: -10% -5%; top: 37%; left: 2%;}

    .vr-man{background-position: 20px 0; height: 200px;}

    .box{margin: 20px 30px 0 0;
    font-size: 15px;}

    .box-3{margin: 40px 40px 0 0; }

    .drone{background-position: -100px 40px; height: 200px;}

    .circle-aboutus{
      border: 0px solid #9E2323;
    }
    .text-box-aboutus{ top: 10%; border-bottom: none; left: -25%;}
    .text-aboutus{font-size: 16px;}
    .contact{width: 100%;}
    .hiring{ height: 300px; width: 100%;}
    .hiring-text{ font-size: 21px;}

    .long-copy {
        width: 80%;
        margin-left: 10%;
    }

    .steps-box { margin-top: 10px; }
    .steps-box:last-child { margin-top: 10px; }
    .works-steps { margin-bottom: 40px; }
    .works-step:last-of-type { margin-bottom: 60px; }

    .app-screen { width: 50%; }

    .icon-small {
        width: 17px;
        margin-right: 5px;
    }

    .city-feature { font-size: 90%; }

    .plan-box {
        width: 100%;
        margin-left: 0%;
    }

    .plan-price { font-size: 250%; }
    .contact-form { width: 80%; }
}





/* Small phones to small tablets: from 481px to 767px */ /* done */
@media only screen and (max-width: 767px) {
    body { font-size: 16px; }


    .row,
    .text-box { padding: 0 4%; top: 20%;}
    .text-box-2 { top: 35%;}

    .text-box-aboutus{ top: auto; border-bottom: none; color: #555; margin: -70% -34%;}
    .text-aboutus{color: #555; font-size: 18px;}
    .background-img-aboutus{height: 0; }

    .btn{ top:50%;left: 1%;}
    .col {
        width: 100%;
        margin: 0 0 4% 0;
    }
    .circle-aboutus{
      border: 0px solid #9E2323;
    }
    .social-media{ margin-left: -60px; display: block; }
    .contact-text{margin-left:90px;}
    .hiring{ height: 300px; width: 100%;}
    .hiring-text{margin: 60px 100px; font-size: 16px;}

    .vr-man{background-position: -250px -0px; height: 200px;}
    .drone{background-position: -250px -0px; height: 200px;}

    .main-nav li {  display: inline-block; margin-left: 50px;}

    h1 { font-size: 180%; }
    h2 { font-size: 150%; }

    .long-copy {
        width: 100%;
        margin-left: 0%;
    }

    .app-screen { width: 40%; }
    .steps-box:first-child { text-align: center; }

    .works-step div {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        padding: 4px;
        font-size: 120%;
    }

    .works-step { margin-bottom: 20px; }
    .works-step:last-of-type { margin-bottom: 20px; }

}



/* Small phones: from 0 to 480px */ /* done */
@media only screen and (max-width: 480px) {

    .text-box{ margin: 10% 37%; }
    .text-box-2 { margin: 27% 37%; }
    .vr-man{background-position: -300px 70px;}
    .drone{background-position: -290px 0px;}

    .btn{top: 55%; left: -8%;}

    .hiring-text{margin: 50px 90px;}
    .social-media{ margin-left: -80px; display: block; }
    .text-box-aboutus{ margin: -70% -34%; border-bottom: none; color: #555;}
}

@media only screen and (max-width: 350px) {

    .text-box{ margin: 15% 37%; }
    .text-box-2 { margin: 45% 37%; }
    .vr-man{background-position: -300px 70px;}
    .drone{background-position: -290px 0px; }

    .btn{top: 70%; left: -8%;}

    .hiring-text{margin: 50px 90px;}
    .social-media{ margin-left: -100px;  }
    .text-box-aboutus{ margin: -70% -34%; border-bottom: none; color: #555;}
}

